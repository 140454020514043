import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/indexLayout'
import Carousel from 'nuka-carousel'

import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql } from 'gatsby'
import panneauMulticoloreJpg from '../images/firstbond/panneau_multicolor.jpg'
import panneauBatiment1Jpg from '../images/firstbond/panneau-batiment1.jpg'
import panneauBatiment2Jpg from '../images/firstbond/panneau-batiment2.jpg'
import panneauBatiment3Jpg from '../images/firstbond/panneau-batiment3.jpg'
import panneauBatiment4Jpg from '../images/firstbond/panneau-batiment4.jpg'
import logoROHSGif from '../images/firstbond/Logo_ROHS.gif'
import sprinterJpg from '../images/firstbond/sprinter.jpg'
import phoneJpg from '../images/firstbond/Telefon.png'
import mailPng from '../images/firstbond/mail.png'
import blancGif from '../images/firstbond/blanc.gif'
import rougeGif from '../images/firstbond/rouge-1.gif'
import bleuGif from '../images/firstbond/bleu.gif'
import anthraciteGif from '../images/firstbond/anthracite.gif'
import jauneGif from '../images/firstbond/jaune.gif'
import noirGif from '../images/firstbond/noir.gif'
import argentGif from '../images/firstbond/argent.gif'
import aluminiumGif from '../images/firstbond/alu.gif'
import ivoireGif from '../images/firstbond/panneau-dibond-ivoire-ral-1015.gif'
import bordeauxGif from '../images/firstbond/panneau-dibond-bordeaux-ral-3004.gif'
import fuschiaPng from '../images/firstbond/panneau-dibond-fuschia-4010.png'
import vertTraficPng from '../images/firstbond/panneau-dibond-vert-trafic-6024.png'
import vertAnglaisPng from '../images/firstbond/panneau-dibond-vert-anglais-ral-6005.png'
import orangePng from '../images/firstbond/panneau-dibond-orange-ral-2011.png'
import miroirArgentPng from '../images/firstbond/panneau-dibond-miroir-argent.png'
import miroirOrPng from '../images/firstbond/panneau-dibond-miroir-or.png'
import goldBrossePng from '../images/firstbond/panneau-dibond-gold-brosse.png'

import chocolatGif from '../images/firstbond/chocolat-ral-8011.gif'
import cuivreBrossePng from '../images/firstbond/panneau-dibond-cuivre-brosse.png'

const IndexPage = (props) => (
  <Layout>
    <div className="page white-bg">
      <div className="container">
        
        <section className="hero-section">


          <div className="hero-section-33">
            <p className="firstbond-header">
              Distributeur de panneaux composite aluminium depuis 2014
            </p>

              <div>Blanc 3mm 25,80€/m² , Couleur 30,80€/m²</div>
              <div>
                <ul id="produit">
                  <li>grande résistance et longévité </li>
                  <li>rapidité d’installation </li>
                  <li>uniformité de la couleur</li>
                  <li>résistants à la corrosion</li>
                  <li>résistants aux intempéries</li>
                </ul>
              </div>

          </div>


          <div className="hero-section-33 min-panneau-size">
            {/*<img src={panneauMulticoloreJpg} width="276" height="141"/>*/}
            <GatsbyImage image={props.data.panneauxToutesCouleurs.childImageSharp.gatsbyImageData} alt="panneau multicolore image" />
          </div>

          <div className="right-panel-fixed-size border-left border-top">
            <img src={sprinterJpg} width="156" height="128"/><br/>
            <p>Consultez le service client pour les délais de livraison.</p>
            <div id="contact">contactez-nous</div>
            <div>
              <img className="small-border-top" src={phoneJpg} width="20" height="18"/><a className="small-border-left" href="tel:+33984417916">09 84 41 79 16</a><br/>
              <div>(Prix d'un appel local)</div>
              <img className="small-border-top" src={mailPng} width="20" height="18"/><a className="small-border-left" href="mailto:contact@firstbond.boutique">contact@firstbond.boutique</a><br/>
            </div>
            <p>SERVICE CLIENT</p>
            <p>Pour répondre à toutes vos questions</p>
            <p>Du lundi au vendredi <br/>
              9h00-19h00</p>
            <p>FIRSTBOND FRANCE</p>
          </div>            

        </section>
      </div>

      <div className="container">
        <section className="hero-section">
        
          <div id="card">

          <table width="100%" border="0" cellSpacing="0" cellPadding="2">
            <thead>
              <tr>
                <th width="5%">&nbsp;</th>
                <th width="10%">Couleurs</th>
                <th width="10%">Epaisseur (mm)</th>
                <th width="10%">Aluminium (mm)</th>
                <th width="10%">Prix/m² (informatif)</th>
                <th width="55%" colSpan="5">Prix panneaux (HT)</th>
                {/*<th width="55%" colSpan="3">Prix panneaux (HT)</th>*/}
              </tr>
              <tr>
                <td width="5%">&nbsp;</td>
                <td width="10%">&nbsp;</td>
                <td width="10%">&nbsp;</td>
                <td width="10%">&nbsp;</td>
                <td width="10%">&nbsp;</td>
                
                <td width="11%" className="header-highlight">1000x2025</td>
                <td width="11%" className="header-highlight">1220x2440</td>
                <td width="11%" className="header-highlight">1250x2550 </td>
                <td width="11%" className="header-highlight">1220x3050</td>
                <td width="11%" className="header-highlight">1500x3050</td>
                
                {/*
                <td width="18%" className="header-highlight">1000x2050</td>
                <td width="18%" className="header-highlight">1220x2440</td>
                <td width="19%" className="header-highlight">1250x2550 </td>
                */}
              </tr>              
            </thead>
            <tbody>
              <tr>
                <td data-label="Couleur&nbsp;&nbsp;&nbsp;"><img src={blancGif} width="22" height="10"/></td>
                <td>blanc ral 9016 </td>
                <td data-label="Epaisseur (mm)&nbsp;&nbsp;&nbsp;"              >2</td>
                <td data-label="Aluminium (mm)&nbsp;&nbsp;&nbsp;"              >0,21</td>
                <td data-label="Prix/m² (informatif)&nbsp;&nbsp;&nbsp;"        >24,60 €</td>
                <td data-label="Prix panneaux 1000x2025	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x2440	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1250x2550	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x3050	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1500x3050	(HT)&nbsp;&nbsp;&nbsp;">112,66 €</td>
              </tr>
              <tr>
                <td data-label="Couleur&nbsp;&nbsp;&nbsp;"><img src={blancGif} width="22" height="10"/></td>
                <td>blanc ral 9016 </td>
                <td data-label="Epaisseur (mm)&nbsp;&nbsp;&nbsp;"              >3</td>
                <td data-label="Aluminium (mm)&nbsp;&nbsp;&nbsp;"              >0,21</td>
                <td data-label="Prix/m² (informatif)&nbsp;&nbsp;&nbsp;"        >25,80 €</td>
                <td data-label="Prix panneaux 1000x2025	(HT)&nbsp;&nbsp;&nbsp;">52,89 €</td>
                <td data-label="Prix panneaux 1220x2440	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1250x2550	(HT)&nbsp;&nbsp;&nbsp;">82,30 €</td>
                <td data-label="Prix panneaux 1220x3050	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1500x3050	(HT)&nbsp;&nbsp;&nbsp;">118,16 €</td>
              </tr>
              <tr>
                <td data-label="Couleur&nbsp;&nbsp;&nbsp;"><img src={blancGif} width="22" height="10"/></td>
                <td>blanc ral 9016 </td>
                <td data-label="Epaisseur (mm)&nbsp;&nbsp;&nbsp;"              >4</td>
                <td data-label="Aluminium (mm)&nbsp;&nbsp;&nbsp;"              >0,30</td>
                <td data-label="Prix/m² (informatif)&nbsp;&nbsp;&nbsp;"        >31,80  €</td>
                <td data-label="Prix panneaux 1000x2025	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x2440	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1250x2550	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x3050	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1500x3050	(HT)&nbsp;&nbsp;&nbsp;">145,64 €</td>
              </tr>
              <tr>
                <td data-label="Couleur&nbsp;&nbsp;&nbsp;"><img src={rougeGif} width="22" height="10"/></td>
                <td>rouge ral 3020</td>
                <td data-label="Epaisseur (mm)&nbsp;&nbsp;&nbsp;"              >3</td>
                <td data-label="Aluminium (mm)&nbsp;&nbsp;&nbsp;"              >0,30</td>
                <td data-label="Prix/m² (informatif)&nbsp;&nbsp;&nbsp;"        >30,80  €</td>
                <td data-label="Prix panneaux 1000x2025	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x2440	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1250x2550	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x3050	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1500x3050	(HT)&nbsp;&nbsp;&nbsp;">141,06 €</td>
              </tr>
              <tr>
                <td data-label="Couleur&nbsp;&nbsp;&nbsp;"><img src={bleuGif} width="22" height="10"/></td>
                <td>bleu ral 5002 </td>
                <td data-label="Epaisseur (mm)&nbsp;&nbsp;&nbsp;"              >3</td>
                <td data-label="Aluminium (mm)&nbsp;&nbsp;&nbsp;"              >0,30</td>
                <td data-label="Prix/m² (informatif)&nbsp;&nbsp;&nbsp;"        >30,80  €</td>
                <td data-label="Prix panneaux 1000x2025	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x2440	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1250x2550	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x3050	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1500x3050	(HT)&nbsp;&nbsp;&nbsp;">141,06 €</td>
              </tr>
              <tr>
                <td data-label="Couleur&nbsp;&nbsp;&nbsp;"><img src={anthraciteGif} width="22" height="10"/></td>
                <td>gris anthracite&nbsp;7016</td>
                <td data-label="Epaisseur (mm)&nbsp;&nbsp;&nbsp;"              >3</td>
                <td data-label="Aluminium (mm)&nbsp;&nbsp;&nbsp;"              >0,30</td>
                <td data-label="Prix/m² (informatif)&nbsp;&nbsp;&nbsp;"        >30,80 €</td>
                <td data-label="Prix panneaux 1000x2025	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x2440	(HT)&nbsp;&nbsp;&nbsp;">&nbsp;</td>
                <td data-label="Prix panneaux 1250x2550	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x3050	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1500x3050	(HT)&nbsp;&nbsp;&nbsp;">141,06 €</td>
              </tr>
              <tr>
                <td data-label="Couleur&nbsp;&nbsp;&nbsp;"><img src={jauneGif} width="22" height="10"/></td>
                <td>jaune ral 1023</td>
                <td data-label="Epaisseur (mm)&nbsp;&nbsp;&nbsp;"              >3</td>
                <td data-label="Aluminium (mm)&nbsp;&nbsp;&nbsp;"              >0,30</td>
                <td data-label="Prix/m² (informatif)&nbsp;&nbsp;&nbsp;"        >30,80 €</td>
                <td data-label="Prix panneaux 1000x2025	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x2440	(HT)&nbsp;&nbsp;&nbsp;">&nbsp;</td>
                <td data-label="Prix panneaux 1250x2550	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x3050	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1500x3050	(HT)&nbsp;&nbsp;&nbsp;">141,06 €</td>
              </tr>
              <tr>
                <td data-label="Couleur&nbsp;&nbsp;&nbsp;"><img src={noirGif} width="22" height="10"/></td>
                <td>noir ral 9005 </td>
                <td data-label="Epaisseur (mm)&nbsp;&nbsp;&nbsp;"              >3</td>
                <td data-label="Aluminium (mm)&nbsp;&nbsp;&nbsp;"              >0,30</td>
                <td data-label="Prix/m² (informatif)&nbsp;&nbsp;&nbsp;"        >30,80 €</td>
                <td data-label="Prix panneaux 1000x2025	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x2440	(HT)&nbsp;&nbsp;&nbsp;">&nbsp;</td>
                <td data-label="Prix panneaux 1250x2550	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x3050	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1500x3050	(HT)&nbsp;&nbsp;&nbsp;">141,06 €</td>
              </tr>
              <tr>
                <td data-label="Couleur&nbsp;&nbsp;&nbsp;"><img src={argentGif} width="22" height="10"/></td>
                <td>gris métallisé ral 9006 </td>
                <td data-label="Epaisseur (mm)&nbsp;&nbsp;&nbsp;"              >3</td>
                <td data-label="Aluminium (mm)&nbsp;&nbsp;&nbsp;"              >0,30</td>
                <td data-label="Prix/m² (informatif)&nbsp;&nbsp;&nbsp;"        >30,80 €</td>
                <td data-label="Prix panneaux 1000x2025	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x2440	(HT)&nbsp;&nbsp;&nbsp;">&nbsp;</td>
                <td data-label="Prix panneaux 1250x2550	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x3050	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1500x3050	(HT)&nbsp;&nbsp;&nbsp;">141,06 €</td>
              </tr>
              {/*
              <tr>
                <td data-label="Couleur&nbsp;&nbsp;&nbsp;"><img src={chocolatGif} width="22" height="10"/></td>
                <td>chocolat ral 8011 </td>
                <td data-label="Epaisseur (mm)&nbsp;&nbsp;&nbsp;"              >3</td>
                <td data-label="Aluminium (mm)&nbsp;&nbsp;&nbsp;"              >0,30</td>
                <td data-label="Prix/m² (informatif)&nbsp;&nbsp;&nbsp;"        >30,80 €</td>
                <td data-label="Prix panneaux 1000x2025	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x2440	(HT)&nbsp;&nbsp;&nbsp;">&nbsp;</td>
                <td data-label="Prix panneaux 1250x2550	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x3050	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1500x3050	(HT)&nbsp;&nbsp;&nbsp;">141,06 €</td>
              </tr>
              */}
              <tr>
                <td data-label="Couleur&nbsp;&nbsp;&nbsp;"><img src={ivoireGif} width="22" height="10"/></td>
                <td>ivoire ral 1015 </td>
                <td data-label="Epaisseur (mm)&nbsp;&nbsp;&nbsp;"              >3</td>
                <td data-label="Aluminium (mm)&nbsp;&nbsp;&nbsp;"              >0,30</td>
                <td data-label="Prix/m² (informatif)&nbsp;&nbsp;&nbsp;"        >30,80  €</td>
                <td data-label="Prix panneaux 1000x2025	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x2440	(HT)&nbsp;&nbsp;&nbsp;">&nbsp;</td>
                <td data-label="Prix panneaux 1250x2550	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x3050	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1500x3050	(HT)&nbsp;&nbsp;&nbsp;">141,06 €</td>
              </tr>
              <tr>
                <td data-label="Couleur&nbsp;&nbsp;&nbsp;"><img src={bordeauxGif} width="22" height="10"/></td>
                <td>bordeaux ral 3004 </td>
                <td data-label="Epaisseur (mm)&nbsp;&nbsp;&nbsp;"              >3</td>
                <td data-label="Aluminium (mm)&nbsp;&nbsp;&nbsp;"              >0,30</td>
                <td data-label="Prix/m² (informatif)&nbsp;&nbsp;&nbsp;"        >30,80  €</td>
                <td data-label="Prix panneaux 1000x2025	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x2440	(HT)&nbsp;&nbsp;&nbsp;">&nbsp;</td>
                <td data-label="Prix panneaux 1250x2550	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x3050	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1500x3050	(HT)&nbsp;&nbsp;&nbsp;">141,06 €</td>
              </tr>
              <tr>
                <td data-label="Couleur&nbsp;&nbsp;&nbsp;"><img src={chocolatGif} width="22" height="10"/></td>
                <td>brun ral 8011 </td>
                <td data-label="Epaisseur (mm)&nbsp;&nbsp;&nbsp;"              >3</td>
                <td data-label="Aluminium (mm)&nbsp;&nbsp;&nbsp;"              >0,30</td>
                <td data-label="Prix/m² (informatif)&nbsp;&nbsp;&nbsp;"        >30,80  €</td>
                <td data-label="Prix panneaux 1000x2025	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x2440	(HT)&nbsp;&nbsp;&nbsp;">&nbsp;</td>
                <td data-label="Prix panneaux 1250x2550	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x3050	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1500x3050	(HT)&nbsp;&nbsp;&nbsp;">141,06 €</td>
              </tr>
              <tr>
                <td data-label="Couleur&nbsp;&nbsp;&nbsp;"><img src={fuschiaPng} width="22" height="10"/></td>
                <td>fuschia 4010</td>
                <td data-label="Epaisseur (mm)&nbsp;&nbsp;&nbsp;"              >3</td>
                <td data-label="Aluminium (mm)&nbsp;&nbsp;&nbsp;"              >0,30</td>
                <td data-label="Prix/m² (informatif)&nbsp;&nbsp;&nbsp;"        >30,80  €</td>
                <td data-label="Prix panneaux 1000x2025	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x2440	(HT)&nbsp;&nbsp;&nbsp;">&nbsp;</td>
                <td data-label="Prix panneaux 1250x2550	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x3050	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1500x3050	(HT)&nbsp;&nbsp;&nbsp;">141,06 €</td>
              </tr>
              <tr>
                <td data-label="Couleur&nbsp;&nbsp;&nbsp;"><img src={vertTraficPng} width="22" height="10"/></td>
                <td>vert trafic 6024</td>
                <td data-label="Epaisseur (mm)&nbsp;&nbsp;&nbsp;"              >3</td>
                <td data-label="Aluminium (mm)&nbsp;&nbsp;&nbsp;"              >0,30</td>
                <td data-label="Prix/m² (informatif)&nbsp;&nbsp;&nbsp;"        >30,80  €</td>
                <td data-label="Prix panneaux 1000x2025	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x2440	(HT)&nbsp;&nbsp;&nbsp;">&nbsp;</td>
                <td data-label="Prix panneaux 1250x2550	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x3050	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1500x3050	(HT)&nbsp;&nbsp;&nbsp;">141,06 €</td>
              </tr>
              <tr>
                <td data-label="Couleur&nbsp;&nbsp;&nbsp;"><img src={vertAnglaisPng} width="22" height="10"/></td>
                <td>vert anglais ral 6005</td>
                <td data-label="Epaisseur (mm)&nbsp;&nbsp;&nbsp;"              >3</td>
                <td data-label="Aluminium (mm)&nbsp;&nbsp;&nbsp;"              >0,30</td>
                <td data-label="Prix/m² (informatif)&nbsp;&nbsp;&nbsp;"        >30,80  €</td>
                <td data-label="Prix panneaux 1000x2025	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x2440	(HT)&nbsp;&nbsp;&nbsp;">&nbsp;</td>
                <td data-label="Prix panneaux 1250x2550	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x3050	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1500x3050	(HT)&nbsp;&nbsp;&nbsp;">141,06 €</td>
              </tr>
              <tr>
                <td data-label="Couleur&nbsp;&nbsp;&nbsp;"><img src={orangePng} width="22" height="10"/></td>
                <td>orange ral 2011</td>
                <td data-label="Epaisseur (mm)"              >3</td>
                <td data-label="Aluminium (mm)"              >0,30</td>
                <td data-label="Prix/m² (informatif)"        >30,80  €</td>
                <td data-label="Prix panneaux 1000x2025	(HT)">-</td>
                <td data-label="Prix panneaux 1220x2440	(HT)">&nbsp;</td>
                <td data-label="Prix panneaux 1250x2550	(HT)">-</td>
                <td data-label="Prix panneaux 1220x3050	(HT)">-</td>
                <td data-label="Prix panneaux 1500x3050	(HT)">141,06 €</td>
              </tr>
              <tr>
                <td data-label="Couleur&nbsp;&nbsp;&nbsp;"><img src={miroirArgentPng} width="22" height="10"/></td>
                <td>miroir argent</td>
                <td data-label="Epaisseur (mm)&nbsp;&nbsp;&nbsp;"              >3</td>
                <td data-label="Aluminium (mm)&nbsp;&nbsp;&nbsp;"              >0,30</td>
                <td data-label="Prix/m² (informatif)&nbsp;&nbsp;&nbsp;"        >75,80  €</td>
                <td data-label="Prix panneaux 1000x2025	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x2440	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1250x2550	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x3050	(HT)&nbsp;&nbsp;&nbsp;">281,98 €</td>
                <td data-label="Prix panneaux 1500x3050	(HT)&nbsp;&nbsp;&nbsp;">-</td>
              </tr>
              <tr>
                <td data-label="Couleur&nbsp;&nbsp;&nbsp;"><img src={miroirOrPng} width="22" height="10"/></td>
                <td>miroir or</td>
                <td data-label="Epaisseur (mm)&nbsp;&nbsp;&nbsp;"              >3</td>
                <td data-label="Aluminium (mm)&nbsp;&nbsp;&nbsp;"              >0,30</td>
                <td data-label="Prix/m² (informatif)&nbsp;&nbsp;&nbsp;"        >75,80  €</td>
                <td data-label="Prix panneaux 1000x2025	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x2440	(HT)&nbsp;&nbsp;&nbsp;">225,88 €</td>
                <td data-label="Prix panneaux 1250x2550	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x3050	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1500x3050	(HT)&nbsp;&nbsp;&nbsp;">-</td>
              </tr>
              <tr>
                <td data-label="Couleur&nbsp;&nbsp;&nbsp;"><img src={goldBrossePng} width="22" height="10"/></td>
                <td>gold brossé </td>
                <td data-label="Epaisseur (mm)&nbsp;&nbsp;&nbsp;"              >3</td>
                <td data-label="Aluminium (mm)&nbsp;&nbsp;&nbsp;"              >0,30</td>
                <td data-label="Prix/m² (informatif)&nbsp;&nbsp;&nbsp;"        >40,80  €</td>
                <td data-label="Prix panneaux 1000x2025	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x2440	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1250x2550	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x3050	(HT)&nbsp;&nbsp;&nbsp;">151,78 €</td>
                <td data-label="Prix panneaux 1500x3050	(HT)&nbsp;&nbsp;&nbsp;">-</td>
              </tr>
              <tr>
                <td data-label="Couleur&nbsp;&nbsp;&nbsp;"><img src={cuivreBrossePng} width="22" height="10"/></td>
                <td>cuivre brossé </td>
                <td data-label="Epaisseur (mm)&nbsp;&nbsp;&nbsp;"              >3</td>
                <td data-label="Aluminium (mm)&nbsp;&nbsp;&nbsp;"              >0,30</td>
                <td data-label="Prix/m² (informatif)&nbsp;&nbsp;&nbsp;"        >40,80  €</td>
                <td data-label="Prix panneaux 1000x2025	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x2440	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1250x2550	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x3050	(HT)&nbsp;&nbsp;&nbsp;">151,78 €</td>
                <td data-label="Prix panneaux 1500x3050	(HT)&nbsp;&nbsp;&nbsp;">-</td>
              </tr>
              <tr>
                <td data-label="Couleur&nbsp;&nbsp;&nbsp;"><img src={aluminiumGif} width="22" height="10"/></td>
                <td>aluminium brossé </td>
                <td data-label="Epaisseur (mm)&nbsp;&nbsp;&nbsp;"              >3</td>
                <td data-label="Aluminium (mm)&nbsp;&nbsp;&nbsp;"              >0,30</td>
                <td data-label="Prix/m² (informatif)&nbsp;&nbsp;&nbsp;"        >38,80  €</td>
                <td data-label="Prix panneaux 1000x2025	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x2440	(HT)&nbsp;&nbsp;&nbsp;">&nbsp;</td>
                <td data-label="Prix panneaux 1250x2550	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1220x3050	(HT)&nbsp;&nbsp;&nbsp;">-</td>
                <td data-label="Prix panneaux 1500x3050	(HT)&nbsp;&nbsp;&nbsp;">177,70 €</td>
              </tr>
            </tbody>
          </table>
          </div>
        </section>
      </div>

      <div class="container">
        <section>
          <br/><br/>
        </section>
      </div>

      <div className="container">
        <section id="images-1" className="images-section">
        
          <div className="hero-image">
            <GatsbyImage
              image={props.data.panneauBatiment3.childImageSharp.gatsbyImageData}
              alt="panneau batiment3 image" />
          </div>

          <div className="hero-image">
            <GatsbyImage
              image={props.data.panneauBatiment4.childImageSharp.gatsbyImageData}
              alt="panneau batiment4 image" />
          </div>
        </section>

        <section id="images-2" className="images-section">
          <div className="hero-image">
            <GatsbyImage
              image={props.data.batimentChina.childImageSharp.gatsbyImageData}
              alt="panneau batiment5 image" />
          </div>

          <div className="hero-image">
            <GatsbyImage
              image={props.data.credenceAluminiumBrosse.childImageSharp.gatsbyImageData}
              alt="panneau credence aluminium brossé image" />
          </div>
        </section>

        <section id="images-3" className="images-section">
          <div className="hero-image">
            <GatsbyImage
              image={props.data.panneauBatiment1.childImageSharp.gatsbyImageData}
              alt="panneau batiment1 image" />
          </div>

          <div className="hero-image">
            <GatsbyImage
              image={props.data.panneauBatiment2.childImageSharp.gatsbyImageData}
              alt="panneau batiment2 image" />
          </div>
        </section>
      </div>


      <div className="container">
        <section id="images-carousel" className="hero-section">

          <div style={{width:'300px'}}>
          <Carousel>
            <GatsbyImage
                  image={props.data.panneauBatiment1.childImageSharp.gatsbyImageData}
                  alt="panneau batiment1 image" />
              <GatsbyImage
                  image={props.data.panneauBatiment2.childImageSharp.gatsbyImageData}
                  alt="panneau batiment2 image" />
              <GatsbyImage
                  image={props.data.batimentChina.childImageSharp.gatsbyImageData}
                  alt="panneau batiment5 image" />
              <GatsbyImage
                  image={props.data.credenceAluminiumBrosse.childImageSharp.gatsbyImageData}
                  alt="panneau credence aluminium brossé image" />
              <GatsbyImage
                  image={props.data.panneauBatiment3.childImageSharp.gatsbyImageData}
                  alt="panneau batiment3 image" />
              <GatsbyImage
                  image={props.data.panneauBatiment4.childImageSharp.gatsbyImageData}
                  alt="panneau batiment4 image" />
          </Carousel>
          </div>

        </section>
      
        <section>
          <br/><br/>
          N'hésitez pas à consulter les <Link to="/tarifs/">tarifs</Link> de notre gamme de panneaux. 
        </section>

        <section>
          <br/><br/>
        </section>
      </div>

      <div className="container">
      <section className="hero-section">
          <div className="info-area">
            <div className="logo-rohs"><img src={logoROHSGif} alt="ROHS" width="121" height="65"/></div>
            <div className="mention-rohs">
              Tous nos produits sont certifiés CE et à la norme RoHS.
            </div>
          </div>
      </section>

      </div>

    </div>

  </Layout>
)

export const firstSiteImages = graphql`fragment firstSiteImages on File {
  childImageSharp {
    gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
  }
}
`

export const pageQuery = graphql`
  query {
    credenceAluminiumBrosse: file(relativePath: { eq: "images/firstbond/credence-aluminium-brosse.jpg" }) {
      ...firstSiteImages
    }
    batimentChina: file(relativePath: { eq: "images/firstbond/batiment-China.jpg" }) {
      ...firstSiteImages
    }
    panneauxToutesCouleurs: file(relativePath: { eq: "images/firstbond/panneaux-toutes-couleurs.jpg" }) {
      ...firstSiteImages
    }
    panneauMulticolore: file(relativePath: { eq: "images/firstbond/panneau_multicolor.jpg" }) {
      ...firstSiteImages
    }
    panneauBatiment1: file(relativePath: { eq: "images/firstbond/panneau-batiment1.jpg" }) {
      ...firstSiteImages
    }
    panneauBatiment2: file(relativePath: { eq: "images/firstbond/panneau-batiment2.jpg" }) {
      ...firstSiteImages
    }
    panneauBatiment3: file(relativePath: { eq: "images/firstbond/panneau-batiment3.jpg" }) {
      ...firstSiteImages
    }
    panneauBatiment4: file(relativePath: { eq: "images/firstbond/panneau-batiment4.jpg" }) {
      ...firstSiteImages
    }
  }
`

export default IndexPage
